import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { useFetch } from "hooks/useFetch";
import { Table } from "react-bootstrap";
import { Loader } from "components/Loader/Loader";
import { TestRadonDetalleItem } from "./TestRadonDetalle/TestRadonDetalleItem";
import { PaginationT } from 'components/PaginationT/PaginationT'
import { getURLQuery, getValuesParams } from "../../../helpers/helpers";
import { useForm } from "hooks/useForm";
import ButtonOrderBy from "components/ButtonOrderBy/ButtonOrderBy";
import { TestRadonFilter } from "./TestRadonDetalle/TestRadonFilter";
import { AuthContext } from 'auth/AuthContext';
import { useLang } from 'language';

export const TestRadon = () => {
    const { user: { lang } } = useContext(AuthContext)
    const [fetchSolicitudes, fetchSolicitudesData] = useFetch('GET', `/clientes/solicitudes_radon`)
    const [fetchParametros, fetchParametrosData] = useFetch('POST', `/parametros_generales`)
    const locale = useLang();
    const language = String(lang).toLowerCase();

    const params = [
        {
            "param": "estados_radon",
            "locale": language,
            "active": true
        },
        {
            param: "tipo_edificio",
            locale: language,
            active: true,
        },
    ];

    useEffect(() => {
        fetchSolicitudes()
        fetchParametros({
            body: params
        })
    }, [])

    // Search
    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
    });

    const handleSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchSolicitudes({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {
        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)
        fetchSolicitudes({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.orderby])

    return (
        <>
            <div className="d-flex animate__animated animate__fadeInUp flex-column w-auto mt-4 mb-5 mx-4">
                <div className="row">

                    <div className="col-md-12">

                        <div className="card border-0 mb-3 shadow-sm">
                            {/*<span className="section-text-header-general">{locale('FILTROS')}</span>*/}
                            <div className="card-body">
                                <div className="container">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h5 className='mt-0 mb-0 text-center'>{locale('FILTROS')}</h5>
                                            </div>
                                    <TestRadonFilter
                                        formValuesSearch={formValuesSearch}
                                        handleInputChangeSearch={handleInputChangeSearch}
                                        handleSearch={handleSearch}
                                        parametros={fetchParametrosData}
                                    />
                                </div>
                            </div>
                       </div> 
                        <div className="card border-0 shadow-sm">
                            {/*<span className="section-text-header-general">{locale('SOLICITUDES')}</span>*/}
                            <div className="container">
                                <div className="card-body">
                                {
                                    fetchSolicitudesData.loading || fetchParametrosData.loading ?
                                        <Loader />
                                        :
                                        <>

                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h5 className='mt-0 mb-0 text-center'>{locale('TEST_RADON')}</h5>
                                                <NavLink className='btn btn-primary' to='/test-radon/nuevo'>
                                                    <FontAwesomeIcon icon={faPlus} className='me-2' /> {locale('TEST_RADON_NUEVO')}
                                                </NavLink>
                                            </div>

                                            <Table responsive className='table table-striped table-bordered'>
                                                <thead>
                                                    <tr style={{ backgroundColor: '#7CA3D1' }}>
                                                        <th className="text-center fw-500" style={{ color: '#fff', width: '150px' }}>
                                                            {/*<ButtonOrderBy
                                            formValuesSearch={formValuesSearch}
                                            handleFormChange={handleFormChange}
                                            label={locale('TEST_RADON_TABLE_NUMERO_SOLICITUD')}
                                            className="text-white"
                                            id='solicitud_id'
                                        />
                                        */}
                                                            {locale('TEST_RADON_TABLE_NUMERO_SOLICITUD')}
                                                        </th>
                                                        <th className="text-center fw-500" style={{ width: '150px', color: '#fff' }}>{locale('TEST_RADON_TABLE_DIRECCION')}</th>
                                                        <th className="text-center fw-500" style={{ width: '150px', color: '#fff' }}>{locale('TEST_RADON_TABLE_TOTAL_DETECTORES')}</th>
                                                        <th className="text-center fw-500" style={{ width: '150px', color: '#fff' }}>{locale('TEST_RADON_TABLE_ESTADO')}</th>
                                                        <th className="text-center fw-500" style={{ width: '150px', color: '#fff' }}>{locale('TEST_RADON_TABLE_FECHA_ESTADO')}</th>
                                                        <th className="text-center fw-500" style={{ width: '150px', color: '#fff' }}>{locale('ACCIONES')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    {
                                                        fetchSolicitudesData.data?.data?.data?.map((item, index) => (
                                                            <TestRadonDetalleItem
                                                                key={index}
                                                                item={item}
                                                                index={index}
                                                                fetchParametrosData={fetchParametrosData}
                                                            // handleShowModalEliminarMuestra={handleShowModalEliminarMuestra}
                                                            />
                                                        ))

                                                    }
                                                    {
                                                        fetchSolicitudesData.data?.data?.data?.length === 0 && <>
                                                            <tr className='text-center'>
                                                                <td className='py-5 ' colSpan={6}>
                                                                    <h5 className='fw-normal'>{locale('TEST_RADON_TABLE_NO_REGISTROS')}</h5>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    }

                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchSolicitudesData?.data?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchSolicitudesData?.data?.data?.per_page}
                                            />
                                        </>
                                }
                            </div>
                          </div>  
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
