export const tarifasBasicoLarga = [
    { numDetectores: 2, tarifaGeneral: '€ 115', tarifaColegido: '€ 100' },
    { numDetectores: 3, tarifaGeneral: '€ 140', tarifaColegido: '€ 125' },
    { numDetectores: 4, tarifaGeneral: '€ 165', tarifaColegido: '€ 150' },
    { numDetectores: 5, tarifaGeneral: '€ 190', tarifaColegido: '€ 175' },
    { numDetectores: 6, tarifaGeneral: '€ 215', tarifaColegido: '€ 200' },
    { numDetectores: 7, tarifaGeneral: '€ 240', tarifaColegido: '€ 225' },
    { numDetectores: 8, tarifaGeneral: '€ 265', tarifaColegido: '€ 250' },
    { numDetectores: 9, tarifaGeneral: '€ 290', tarifaColegido: '€ 275' },
    { numDetectores: 10, tarifaGeneral: '€ 315', tarifaColegido: '€ 300' },
    { numDetectores: 11, tarifaGeneral: '€ 340', tarifaColegido: '€ 325' },
    { numDetectores: 12, tarifaGeneral: '€ 365', tarifaColegido: '€ 350' },
    { numDetectores: 13, tarifaGeneral: '€ 390', tarifaColegido: '€ 375' },
    { numDetectores: 14, tarifaGeneral: '€ 415', tarifaColegido: '€ 400' },
    { numDetectores: 15, tarifaGeneral: '€ 440', tarifaColegido: '€ 425' },
    { numDetectores: 16, tarifaGeneral: '€ 465', tarifaColegido: '€ 450' },
    { numDetectores: 17, tarifaGeneral: '€ 490', tarifaColegido: '€ 475' },
    { numDetectores: 18, tarifaGeneral: '€ 515', tarifaColegido: '€ 500' },
    { numDetectores: 19, tarifaGeneral: '€ 540', tarifaColegido: '€ 525' },
    { numDetectores: 20, tarifaGeneral: '€ 565', tarifaColegido: '€ 550' },
];

export  const tarifasBasicoCorta = [
    { numDetectores: 2, tarifaGeneral: '€ 125', tarifaColegido: '€ 110' },
    { numDetectores: 3, tarifaGeneral: '€ 150', tarifaColegido: '€ 135' },
    { numDetectores: 4, tarifaGeneral: '€ 175', tarifaColegido: '€ 160' },
    { numDetectores: 5, tarifaGeneral: '€ 200', tarifaColegido: '€ 185' },
    { numDetectores: 6, tarifaGeneral: '€ 225', tarifaColegido: '€ 210' },
    { numDetectores: 7, tarifaGeneral: '€ 250', tarifaColegido: '€ 235' },
    { numDetectores: 8, tarifaGeneral: '€ 275', tarifaColegido: '€ 260' },
    { numDetectores: 9, tarifaGeneral: '€ 300', tarifaColegido: '€ 285' },
    { numDetectores: 10, tarifaGeneral: '€ 325', tarifaColegido: '€ 310' },
    { numDetectores: 11, tarifaGeneral: '€ 350', tarifaColegido: '€ 335' },
    { numDetectores: 12, tarifaGeneral: '€ 375', tarifaColegido: '€ 360' },
    { numDetectores: 13, tarifaGeneral: '€ 400', tarifaColegido: '€ 385' },
    { numDetectores: 14, tarifaGeneral: '€ 425', tarifaColegido: '€ 410' },
    { numDetectores: 15, tarifaGeneral: '€ 450', tarifaColegido: '€ 435' },
    { numDetectores: 16, tarifaGeneral: '€ 475', tarifaColegido: '€ 460' },
    { numDetectores: 17, tarifaGeneral: '€ 500', tarifaColegido: '€ 485' },
    { numDetectores: 18, tarifaGeneral: '€ 525', tarifaColegido: '€ 510' },
    { numDetectores: 19, tarifaGeneral: '€ 550', tarifaColegido: '€ 535' },
    { numDetectores: 20, tarifaGeneral: '€ 575', tarifaColegido: '€ 560' },
];

export const tarifaspProfesionalLarga = [
    { numDetectores: 2, tarifaGeneral: '€ 115', tarifaColegido: '€ 100' },
    { numDetectores: 3, tarifaGeneral: '€ 140', tarifaColegido: '€ 125' },
    { numDetectores: 4, tarifaGeneral: '€ 165', tarifaColegido: '€ 150' },
    { numDetectores: 5, tarifaGeneral: '€ 190', tarifaColegido: '€ 175' },
    { numDetectores: 6, tarifaGeneral: '€ 215', tarifaColegido: '€ 200' },
    { numDetectores: 7, tarifaGeneral: '€ 240', tarifaColegido: '€ 225' },
    { numDetectores: 8, tarifaGeneral: '€ 265', tarifaColegido: '€ 250' },
    { numDetectores: 9, tarifaGeneral: '€ 290', tarifaColegido: '€ 275' },
    { numDetectores: 10, tarifaGeneral: '€ 315', tarifaColegido: '€ 300' },
    { numDetectores: 11, tarifaGeneral: '€ 340', tarifaColegido: '€ 325' },
    { numDetectores: 12, tarifaGeneral: '€ 365', tarifaColegido: '€ 350' },
    { numDetectores: 13, tarifaGeneral: '€ 390', tarifaColegido: '€ 375' },
    { numDetectores: 14, tarifaGeneral: '€ 415', tarifaColegido: '€ 400' },
    { numDetectores: 15, tarifaGeneral: '€ 440', tarifaColegido: '€ 425' },
    { numDetectores: 16, tarifaGeneral: '€ 465', tarifaColegido: '€ 450' },
    { numDetectores: 17, tarifaGeneral: '€ 490', tarifaColegido: '€ 475' },
    { numDetectores: 18, tarifaGeneral: '€ 515', tarifaColegido: '€ 500' },
    { numDetectores: 19, tarifaGeneral: '€ 540', tarifaColegido: '€ 525' },
    { numDetectores: 20, tarifaGeneral: '€ 565', tarifaColegido: '€ 550' },
];