import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "hooks/useForm";
import { useFetch } from 'hooks/useFetch';
import { Alert, Button } from "react-bootstrap";
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { Loader } from 'components/Loader/Loader';
import { InputCheck } from 'components/Inputs/InputCheck.jsx'
import { ModalCambiarPagador } from './ModalCambiarPagador';
import { ModalInfoPagador } from './ModalInfoPagador';
import { useLang } from 'language';
import { ModalCondicionesContratacion } from "./ModalCondicionesContratacion";

export const Facturacion = ({ formTestRadon, indexFocusWizard, setIndexFocusWizard, parametros }) => {

    const locale = useLang();

    const [checked, setChecked] = useState(false);

    const [checkedManifiesto, setCheckedManifiesto] = useState({
        m1: false,
        m2: false,
        m3: false,
    });

    const [fetchResumen, fetchResumenData] = useFetch('GET', `/clientes/solicitudes_radon/sensores_resumen`);
    // const [fetchMisDatos, fetchMisDatosData] = useFetch('GET', `/clientes/usuarios/me`)
    const [fetchMisDatos, fetchMisDatosData] = useFetch('POST', `/clientes/solicitudes_radon/${formTestRadon.id}/datos_factura`)

    const [fetchPagar, fetchPagarData] = useFetch('POST', `/clientes/solicitudes_radon/realizar_pago`, 'text')
    const [dataHtml, setdataHtml] = useState('')
    const inputEl = useRef(null);
    const [showModalCambiarPagador, setShowModalCambiarPagador] = useState(false);
    const [pagador, setPagador] = useState(0);
    const [documento, setDocumento] = useState(1);

    const [formMisDatos, handleInputChangeMisDatos, handleFormChangeMisDatos] = useForm({
        datos_fact_id: '',
        datos_fact_confirmado: '',
        datos_fact_nombre: '',
        datos_fact_apellidos: '',
        datos_fact_tipo_doc_id: '',
        datos_fact_documento: '',
        datos_fact_email: '',
        datos_fact_telefono: '',
        datos_fact_num_colegiado: '',
        datos_fact_fecha_creacion: '',
        datos_fact_fecha_modificacion: '',
        datos_fact: {}
    })

    const [formPagar, handleInputChangePagar, handleFormChangePagar, handleFormChangePagarReset] = useForm({
        solicitud_radon_id: formTestRadon.id,
        url_redireccion_ok: `${process.env.PUBLIC_URL}/test-radon/${formTestRadon.id}`,
        url_redireccion_ko: `${process.env.PUBLIC_URL}/test-radon/${formTestRadon.id}`,
    });


    const extraParroquia = [
        46,
        33
    ];

    useEffect(() => {
        fetchMisDatos()
        // fetchPrecios()
        fetchResumen({
            id: formTestRadon.id
        });
    }, [])

    useEffect(() => {
        if (fetchMisDatosData.data) {
            console.log('fetchMisDatosData.data', fetchMisDatosData.data);
            handleFormChangeMisDatos({
                ...fetchMisDatosData.data?.datos_facturacion
            })
        }
    }, [fetchMisDatosData])

    const handleClickCambiarABorrador = () => {
        setIndexFocusWizard(indexFocusWizard - 1)
    }

    const handlePagar = () => {
        fetchPagar({
            body: { ...formPagar, datos_peticionario: checked }
        })
    }

    useEffect(() => {
        if (fetchPagarData.data) {

            setdataHtml(fetchPagarData.data)
        }
    }, [fetchPagarData])


    useEffect(() => {

        if (dataHtml) {

            if (inputEl.current) {
                try {
                    inputEl.current.children[0].submit()

                } catch (error) {

                    console.log(error);
                }
            }
        }

    }, [dataHtml])


    const handleShowModalCambiarPagador = () => setShowModalCambiarPagador(true);
    const handleCloseModalCambiarPagador = () => {
        setPagador(0);
        setShowModalCambiarPagador(false);
    }

    const handleChangePagador = (e) => {
        setPagador(Number(e.target.value))
    }

    const handleChangeDocumento = (e) => {
        setDocumento(e.target.value)
    }

    const [showModalNuevoPagador, setShowModalNuevoPagador] = useState(false);
    const handleCloseModalNuevoPagador = () => setShowModalNuevoPagador(false)
    const handleShowModalNuevoPagador = () => setShowModalNuevoPagador(true);


    const [showModalManifiesto, setShowModalManifiesto] = useState({
        value: false,
        key: '',
        to: '',
    });
    const handleCloseModalManifiesto = () => setShowModalManifiesto({
        value: false,
        key: '',
        to: '',
    })
    const handleShowModalManifiesto = ({ key, to }) => setShowModalManifiesto({
        value: true,
        key,
        to,
    })

    return (
        <>
            <div className="container animate__faster animate__animated animate__fadeInLeft">
                <div className="row py-4 px-3">
                    <div className="col-12">
                        <h5 className='mb-4 text-center'>{locale('DATOS_FACTURACION')}</h5>
                    </div>

                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div className="col-md-9 col-lg-8 col-xl-8">
                            {
                                (fetchMisDatosData.loading || fetchResumenData.loading) ?
                                    <Loader />
                                    :
                                    <>

                                        <div className="row justify-content-center">
                                            <div className="col-lg-7">
                                                <div className="card card px-3 py-2">
                                                    <div>
                                                        <div className="d-block py-2">
                                                            <p className='d-inline-block ms-0 mb-0'>
                                                            {fetchMisDatosData.data?.datos_facturacion?.datos_fact_nombre || ''} 
                                                            {fetchMisDatosData.data?.datos_facturacion?.datos_fact_apellidos || ''}</p>
                                                        </div>
                                                        <div className="d-inline-block me-3 py-2">
                                                            <p className=' d-inline-block mb-0'>{fetchMisDatosData.data?.datos_facturacion?.datos_fact_documento}</p>
                                                        </div>

                                                        <div className="line-dashed"></div>

                                                        <div className="d-flex justify-content-between align-items-center py-2">
                                                            <h5 className='h6 d-inline-block mb-0'>{locale('TEST_RADON_TOTAL_SENSORES')}</h5>
                                                            <span className='d-inline-block ms-2 mb-0'>{Number(fetchResumenData.data?.data?.sensores?.cantidad || 0) + Number(fetchResumenData.data?.data?.sensores_extra?.cantidad || 0)}</span>
                                                        </div>

                                                        {
                                                            (fetchResumenData.data?.data?.envio?.cantidad > 0) &&
                                                            <div className="d-flex justify-content-between align-items-center py-2">
                                                                <h5 className='h6 d-inline-block mb-0'>{locale('ENVIO_A_CANARIAS')}</h5>
                                                                <span className='d-inline-block ms-2 mb-0'>{fetchResumenData.data?.data?.envio?.precio} €</span>
                                                            </div>
                                                        }

                                                        <div className="line-dashed"></div>

                                                        <div className="d-flex justify-content-end align-items-center py-2">
                                                            <h5 className='h6 d-inline-block mb-0'>{locale('TEST_RADON_TOTAL_IMPORTE')} </h5>
                                                            <span className='d-inline-block ms-2 mb-0'>{fetchResumenData.data?.data?.precio_total} €</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {formTestRadon.estado === 'radon_estado_borrador' &&

                                            <>

                                                <div className="border-bottom">
                                                    <div className="my-3 col-12 ">
                                                        <InputCheck
                                                            label={
                                                                <small className="d-block">
                                                                    <span>{locale('P_MANIFIESTO_INFORMACION_PROTECCION_DATOS')}</span> <span className="color-primary cursor-pointer" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleShowModalManifiesto({
                                                                            key: 'm1',
                                                                            to: '10',
                                                                        })
                                                                    }
                                                                    }>
                                                                        {locale('P_MANIFIESTO_INFORMACION_PROTECCION_DATOS_2')}
                                                                    </span>

                                                                </small>
                                                            }
                                                            name="manifiesto1"
                                                            value={checkedManifiesto.m1}
                                                            onChange={
                                                                (e) => {
                                                                    setCheckedManifiesto({
                                                                        ...checkedManifiesto,
                                                                        m1: e?.target?.checked
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                    <div className="my-3 col-12 ">
                                                        <InputCheck
                                                            label={
                                                                <small className="d-block">
                                                                    <span>{locale('p_MANIFIESTO_DATOS_TERCERAS_PERSONAS')}</span> <span onClick={
                                                                        (e) => {
                                                                            e.preventDefault()
                                                                            handleShowModalManifiesto({
                                                                                key: 'm2',
                                                                                to: '10',
                                                                            })
                                                                        }
                                                                    } className="color-primary cursor-pointer ">
                                                                        {locale('p_MANIFIESTO_DATOS_TERCERAS_PERSONAS_2')}
                                                                    </span>
                                                                </small>
                                                            }
                                                            name="manifiesto2"
                                                            value={checkedManifiesto.m2}
                                                            onChange={
                                                                (e) => {
                                                                    setCheckedManifiesto({
                                                                        ...checkedManifiesto,
                                                                        m2: e?.target?.checked
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                    <div className="my-3 col-12 ">
                                                        <InputCheck
                                                            label={
                                                                <small className="d-block">
                                                                    {locale('P_MANIFIESTO_DATOS_PERSONALES_FACILITADOS')}
                                                                </small>
                                                            }
                                                            name="manifiesto3"
                                                            value={checkedManifiesto.m3}
                                                            onChange={
                                                                (e) => {
                                                                    setCheckedManifiesto({
                                                                        ...checkedManifiesto,
                                                                        m3: e?.target?.checked
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className='d-flex flex-wrap justify-content-between mt-3 mb-2'>
                                                    <Button variant="outline-primary-radon" onClick={handleShowModalCambiarPagador} className='px-3'>
                                                        {locale('TEST_RADON_CAMBIAR_PAGADOR')}
                                                    </Button>
                                                    {/* <div></div> */}
                                                    <ButtonSpinner
                                                        disabled={formTestRadon.estado !== 'radon_estado_borrador' ||
                                                            (!checkedManifiesto.m1 || !checkedManifiesto.m2 || !checkedManifiesto.m3) ? true : false}
                                                        variant="primary-radon" type="submit" className="ms-3 px-3"
                                                        handleSubmit={handlePagar}
                                                        fetchDataLoading={[fetchPagarData.loading]}
                                                        value={locale('PAGAR')}
                                                    />

                                                </div>

                                                <div ref={inputEl} dangerouslySetInnerHTML={{ __html: dataHtml }}></div>
                                            </>

                                        }

                                        {formTestRadon.estado !== 'radon_estado_borrador' &&
                                            <div className='mt-3'>
                                                <Alert variant='success' className='py-1 text-center'>
                                                    <small>
                                                        {locale('TEST_RADON_PAGO_CORRECTO')}
                                                    </small>
                                                </Alert>
                                            </div>
                                        }

                                        <div>
                                            {/*
                                            // !fetchMisDatosData.data?.data?.usuario_num_colegiado &&
                                            <small className='text-decoration-underline cursor-pointer' onClick={() => null}>
                                                Pagar presencialment (únicament per a transferències)
                                            </small>
                                        */}

                                        </div>
                                    </>
                            }
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="d-flex flex-nowrap justify-content-between align-items-center">
                            <div className='m-0'>
                                <ButtonSpinner
                                    variant="outline-primary-radon" type="submit" className="px-3"
                                    handleSubmit={() => setIndexFocusWizard(1)} fetchDataLoading={[fetchPagarData.loading]}
                                    value={locale('TEST_RADON_VOLVER')} icon={<FontAwesomeIcon icon={faArrowLeft} className="me-1" />}
                                />

                            </div>
                            <div className='m-0'>

                                <ButtonSpinner
                                    disabled={formTestRadon.estado === 'radon_estado_borrador' ? true : false}
                                    variant="outline-primary-radon" type="submit" className="ms-3 px-3"
                                    handleSubmit={() => setIndexFocusWizard(3)} fetchDataLoading={[false]}
                                    value={locale('TEST_RADON_CONTINUAR')} iconRight={<FontAwesomeIcon icon={faArrowRight} className="ms-1" />}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <ModalInfoPagador showModal={showModalCambiarPagador}
                handleCloseModal={handleCloseModalCambiarPagador}
                handleChangePagador={handleChangePagador}
                dataradon={formTestRadon}
                handleChangeDocumento={handleChangeDocumento}
                handleShowModalNuevoPagador={handleShowModalNuevoPagador}
                formMisDatos={formMisDatos}
                pagador={pagador}
                documento={documento}
                fetchMisDatos={fetchMisDatos}
            />

            <ModalCambiarPagador
                showModalNuevoPagador={showModalNuevoPagador}
                handleCloseModalNuevoPagador={handleCloseModalNuevoPagador}
                handleShowModalNuevoPagador={handleShowModalNuevoPagador}
                setShowModalNuevoPagador={setShowModalNuevoPagador}
                fetchParametrosData={parametros}
                solicitud_id={formTestRadon.id}
                documento={documento}
                pagador={pagador}
                fetchMisDatos={fetchMisDatos}
            />

            <ModalCondicionesContratacion
                setCheckedManifiesto={setCheckedManifiesto}
                checkedManifiesto={checkedManifiesto}
                showModal={showModalManifiesto}
                handleCloseModal={handleCloseModalManifiesto}
            />

        </>
    )
};
