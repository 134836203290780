const lang = {
    ES: {
        INICIAR_SESION: "Iniciar sesión",
        INSCRIPCION: "Inscripción",
        OLVIDASTE_CONTRASENA: "¿Olvidaste tu contraseña?",
        AVISO_LEGAL: "Aviso Legal",
        POLITICA_PRIVACIDAD: "Política de Privacidad",
        POLITICA_COOKIES: "Política de Cookies",
        ESPANIOL: "Español",
        CATALAN: "Catalán",
        CONTRASENIA: "Contraseña",
        PROCESOS: "Procesos",
        PROCESO: "Proceso",
        CAMBIAR_CONTRASENIA: "Cambiar contraseña",
        SALIR: "Salir",
        NUM: "Núm",
        LISTA_DE: "Lista de",
        FILTRO: "Filtro",
        BUSCAR: "Buscar",
        PLANTILLAS_MENSAJES: "Plantillas Mensajes",
        VER_MAS: "Ver más de nuestros servicios",
        COLABORACION: "Con la colaboración de",
        NOMBRE_DEL_TRAMITADOR: 'Nombre del tramitador',
        DESDE: "Desde",
        HASTA: "Hasta",
        ESTADO: "Estado",
        FECHA_ESTADO: "Fecha estado",
        AVISAR_PENDIENTES_FIRMAR: "Avisar a pendientes de firmar",
        DATOS_GENERALES: "Datos generales",
        HISTORICO: "Histórico",
        PLANTILLAS: "Plantillas",
        DESCRIPCION_PLANTILLA: "Descripción de la plantilla",
        REMITENTE: "Remitente",
        CORREO_REMITENTE: "Correo del remitente",
        ASUNTO: "Asunto",
        MENSAJE: "Mensaje",
        CODIGOS_ADICIONALES: "Códigos adicionales",
        PERTENECE_PROCESO_DE: "Pertenece al proceso de",
        TRAMITADOR: "Tramitador",
        TAMANIO: "Tamaño",
        FORMATO: "Formato",
        DESCARGAR: "Descargar",
        DOCUMENTO: "Documento",
        USUARIOS: "Usuarios",
        LOGS: "Logs",
        MANTENIMIENTO: "Mantenimiento",
        ACTUALIZAR: "Actualizar",
        COPYRIGHT: "© Col·legi d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Barcelona",
        ID_INTERNO_PROCESO: "ID",
        NIF_TRAMITADOR: "NIF Tramitador",
        FINALIZADO: "Finalizado",
        FECHA_MODIFICACION: "Fecha modificación",
        NUMERO_DE_LA_MUESTRA: 'Núm. de la muestra',
        AGREGAR_PLANTA: 'Agregar planta',
        TIPO_PLANTA: 'Tipo de planta',
        EDITAR_PLANTA: 'Editar planta',
        GUARDAR: 'Guardar',
        PRUEBAS_DE_LABORATORIO: `Pruebas de laboratorio`,
        COLEGIADOS: 'Colegiados',
        OTROS_USUARIOS: 'Otros usuarios',
        TEST_RADON: 'Test radón',
        TEST_RADON_NUEVO: 'Nuevo Test Radón',
        TEST_RADON_WIZAR_NIVEL1: 'Datos para la solicitud',
        TEST_RADON_WIZAR_NIVEL2: 'Determinación detectores',
        TEST_RADON_WIZAR_NIVEL3: 'Facturación y pago',
        TEST_RADON_WIZAR_NIVEL4: 'Medición',
        TEST_RADON_WIZAR_NIVEL5: 'Resultado',
        TEST_RADON_ELIMINAR: 'Eliminar Solicitud',
        TEST_RADON_HOME_TITULO: 'Selecciona el tipo de Test Radón que quieres solicitar',
        TEST_RADON_BASIC_TITULO: 'Test Radón Básico',
        TEST_RADON_HOME_BASIC_TITULO: 'Test Radón <br/> Básico',
        TEST_RADON_BASIC_DESCRIPCION: 'El Test para conocer el nivel de concentración de radón de mi vivienda',
        TEST_RADON_BASIC_NOTA: 'Nota: aqui',
        TEST_RADON_PROD_TITULO: 'Test Radón Profesional (justificación CTE DB HS6)',
        TEST_RADON_PROD_DESCRIPCION: 'El Test para profesionales que deban verificar y justificar el cumplimiento de la exigencia del CTE DB HS6',
        TEST_RADON_PROD_NOTA: 'Nota: Únicamente permite la solicitud de la medición de larga duración. Según el fabricante se recomienda que los detectores permanezcan expuestos un período mínimo de 3 meses',
        TEST_RADON_HOME_SELECCIONAR: 'Seleccionar',
        TEST_RADON_TIPO_INTERVENCION: 'Típo de intervención',
        TEST_RADON_TIPO_AMPLIACION: 'Típo de ampliación',
        TEST_RADON_TIPO_MEDICION: 'Tipo de Medición que deseas realizar',
        TEST_RADON_DATOS_EDIFICIO: 'Datos de la vivienda/edificio a medir:',
        TEST_RADON_TIPO_EDIFICIO: 'Tipología de edificio *',
        TEST_RADON_PISO: 'Piso *',
        TEST_RADON_OTROS: 'Indica la tipologia de edificio',
        TEST_RADON_DATOS_PROPIETARIO: 'Datos propietario',
        TEST_RADON_TIPO_PERSONA: 'Típo de persona *',
        TEST_RADON_RAZON_SOCIAL: 'Razón social *',
        TEST_RADON_NOMBRE: 'Nombre *',
        TEST_RADON_APELLIDO: 'Apellido *',
        TEST_RADON_CORREO: 'Correo electrónico *',
        TEST_RADON_TELEFONO: 'Teléfono *',
        TEST_RADON_ACTUA: 'Actua como:',
        TEST_RADON_TITULO: 'Título:',
        TEST_RADON_NUMERO_COLEGIADO: 'Número de colegiado:',
        TEST_RADON_ENTIDAD: 'Nombre de la Entidad:',
        TEST_RADON_ANOTACIONES: 'ANOTACIONES (puedes indicar cualquier dato no especificado en los campos anteriores)',
        TEST_RADON_UBICACION_EDIFICIO: 'Ubicación de la vivienda/edificio a medir:',
        TEST_RADON_DIRECCION: 'Dirección *',
        TEST_RADON_PUERTA: 'Puerta',
        TEST_RADON_NUMERO : 'Número *',
        TEST_RADON_CODIGO_POSTAL: 'Código Postal',
        TEST_RADON_MUNICIPIO: 'Municipio:',
        TEST_RADON_ANIO_CONTRUCCION: 'Año de construcción',
        TEST_RADON_REFORMA_PREVIAS: 'Reformas previas del edificio',
        TEST_RADON_REFORMA_INDICA: 'Indica tipo de reforma y año',
        TEST_RADON_ZONA: 'Zona',
        TEST_RADON_LOCALIZAR: 'Localizar',
        TEST_RADON_SUPERFICIE: 'Superficie útil',
        TEST_RADON_DIRECCION_DATOS_PETICIONARIO: 'Datos peticionario',
        TEST_RADON_DIRECCION_DATOS_EDIFICIO: 'Datos de la vivienda/edificio a medir:',
        TEST_RADON_DIRECCION_DATOS_OTROS: 'Otra',
        TEST_RADON_DETALLE_DETECTORES: 'Envío de detectores: Indique la dirección donde desea recibir los detectores',
        TEST_RADON_DETERMINACION: 'Determinación de número de detectores:',
        TEST_RADON_ACREDITADOR: 'La persona que acredita el cumplimiento de los siguientes datos es:',
        TEST_RADON_CONTINUAR: 'Continuar',
        TEST_RADON_BORRADOR: 'Guardar como borrador',
        TEST_RADON_VOLVER: 'Regresar',
        TEST_RADON_AGREGAR_PLANTA: 'Agregar planta',
        TEST_RADON_DISPONE_LOCAL: 'Local habitable',
        TEST_RADON_LOCALE_PLANTA: 'Locales habitables de la planta',
        TEST_RADON_METRO_PLANTA: 'M2 por planta',
        TEST_RADON_ALCALA_PLANTA: 'Altura planta (m)',
        TEST_RADON_TIPOLOGIA_PLANTA: 'Tipología de planta',
        TEST_RADON_NUMERO_DETECTOR: 'N° detectores',
        TEST_RADON_LOADING: 'Cargando espere por favor...',
        TEST_RADON_NO_RESULTADO: 'No hay resultados para mostrar',
        TEST_RADON_DISPONE_LOCAL_FORM: 'Local habitable',
        TEST_RADON_IDENTIFIQUE_USO_FORM:'Identifique el uso (p. ejemplo: aparcamiento, trastero, etc).',
        TEST_RADON_LOCALES_HABITABLE:'Locales Habitables (p. ejemplo: sala de estar, dormitorio, baño, despacho, cocina).',
        TEST_RADON_M_PLANTA_FORM:'M2 superficie útil planta *',
        TEST_RADON_ALTURA_PLANTA_FORM: 'Altura planta (m) *',
        TEST_RADON_MODAL_BTN_CERRAR: 'Cerrar',
        TEST_RADON_TOTAL_SENSORES: 'Total detectores',
        TEST_RADON_TOTAL_EXTRA_SENSORES: 'Adicional',
        TEST_RADON_TOTAL_PROFESIONAL_SENSORES: 'Total de sensores profesionales:',
        TEST_RADON_FACTURACION: 'Dades de facturació',
        TEST_RADON_PRECIO_UNITARIO: 'Precio unitario por detector (Iva incluido)',
        TEST_RADON_TOTAL_IMPORTE: 'Importe total a pagar:',
        TEST_RADON_METODO_PAGO: 'Seleccione un metodo de pago:',
        TEST_RADON_CAMBIAR_PAGADOR: 'Quiero cambiar el pagador de la factura',
        TEST_RADON_CAMBIAR_PAGADOR_INFO: 'Si cambias el pagador a empresa, debes marcar pago en visa. Sólo se puede realizar domiciliación a los colegiados',
        TEST_RADON_PAGO_CORRECTO: 'Solicitud pagada correctamente',
        TEST_RADON_MEDICION_TITULO: 'Detalle Sensores',
        TEST_RADON_MEDICION_TABLE_PLANTA: 'Planta',
        TEST_RADON_MEDICION_TABLE_NUMERO_SENSOR: 'N° Sensor',
        TEST_RADON_MEDICION_TABLE_FECHA_DESDE: 'Fecha desde',
        TEST_RADON_MEDICION_TABLE_FECHA_HASTA: 'Fecha hasta',
        TEST_RADON_MEDICION_TABLE_NOTA: 'Nota',
        TEST_RADON_SINCRONIZAR: 'Sincronizar en Radonova',
        TEST_RADON_RECIBIDO_DETECTORES: 'He recibido los detectores',
        TEST_RADON_ENVIO_LABORATORIO: 'Enviar a laboratorio',
        TEST_RADON_PENDIENTE_ANALISIS: 'Los detectores estan en proceso de analisis',
        TEST_RADON_SENSOR_FORM_TITULO: 'Editar Detector',
        TEST_RADON_SENSOR_FORM_PLANTAS: 'Plantas *',
        TEST_RADON_SENSOR_FORM_TIPO_PLANTA: 'Típo de planta *',
        TEST_RADON_SENSOR_FORM_NUMERO_DETECTOR: 'N° Detector *',
        TEST_RADON_SENSOR_FORM_FECHA_INICIO: 'Fecha inicio medición *',
        TEST_RADON_SENSOR_FORM_FECHA_FIN: 'Fecha fin medición *',
        TEST_RADON_SENSOR_FORM_UBICACION: 'Ubicación: *',
        TEST_RADON_SENSOR_FORM_NO_UTILIZADO: 'No utilizado',
        TEST_RADON_SENSOR_BTN_EDITAR: 'Editar',
        TEST_RADON_ESTADO_TITULO: '¿Estás seguro de confirmar esta acción?',
        TEST_RADON_ESTADO_CONFIRMAR: 'Sí, Confirmar',
        TEST_RADON_CONFIRMAR_BORRADOR: 'Seguro que desea eliminar la solicitud?',
        TEST_RADON_BORRADOR_BTN: 'Sí, eliminar',
        TEST_RADON_SOLUCIONES_TITULO: 'Soluciones implementadas para la protección frente al radón:',
        TEST_RADON_MOTIVO_MEDIACION: 'Motivo de la medición:',
        TEST_RADON_FILTRO_TIPO_TEST: 'Tipo de Test:',
        TEST_RADON_FILTRO_TIPO_EDIFICIO: 'Tipologia de edificio a medir:',
        TEST_RADON_FILTRO_PROVINCIA: 'Provincia:',
        TEST_RADON_FILTRO_MUNICIPIO: 'Municipio:',
        TEST_RADON_FILTRO_ZONA: 'Zona:',
        TEST_RADON_FILTRO_NUMERO_DETECTOR: 'Número de detector:',
        TEST_RADON_FILTRO_NUMERO_SOLICITUD: 'N° Solicitud:',
        TEST_RADON_FILTRO_ESTADO: 'Estado:',
        TEST_RADON_FILTRO_DESDE: 'Fecha desde:',
        TEST_RADON_FILTRO_HASTA: 'Fecha hasta:',
        TEST_RADON_FILTRO_PERSONA: 'Persona:',
        TEST_RADON_TABLE_NUMERO_SOLICITUD: 'N° Solicitud',
        TEST_RADON_TABLE_DIRECCION: 'Dirección',
        TEST_RADON_TABLE_TOTAL_DETECTORES: 'Total detectores',
        TEST_RADON_TABLE_ESTADO: 'Estado',
        TEST_RADON_TABLE_FECHA_ESTADO: 'Fecha estado',
        TEST_RADON_TABLE_NO_REGISTROS: 'No hay solicitudes que mostrar',
        TEST_RADON_DESCARGAR_INFORME: 'Descargar resultado Test Radón',
        TEST_RADON_RESULTADO_SOLICITUD:'Resultado final de la solicitud',
        TEST_RADON_ANALISIS_OK: 'Analisis finalizado correctamente',
        HOME_CONTACTO: 'Contacto',
        HOME_HEADER_TITULO: 'Test Radón',
        HOME_HEADER_SUB_TITULO: 'Quizás no lo veas, pero puede estar',
        HOME_HEADER_TEXTO_1: 'El radón es un gas radiactivo que no se puede ver ni oler y cuya exposición durante un largo período de tiempo en espacios cerrado, es perjudicial para la salud. Por tanto, la única forma de saber si se está expuesto es medir su concentración en el aire.',
        HOME_HEADER_TEXTO_: 'El Test Radón es un método de medición al alcance de los',
        HOME_HEADER_TEXTO__: 'que quieran dar cumplimiento reglamentario a sus proyectos de edificación y poner a disposición de la',
        HOME_HEADER_TEXTO___: "la opción de conocer la concentración de radón en su edificio.",
        HOME_HEADER_TEXTO_SPAN1: "profesionales",
        HOME_HEADER_TEXTO_SPAN2: "ciudadanía",
        HOME_HEADER_INFO_USUARIO: "Información previa para los usuarios",
        HOME_BTN_SABER_MAS: 'Saber más',
        HOME_BTN_LOGIN: 'Iniciar sesión',
        HOME_BTN_REGISTRO: 'Registrarse',
        HOME_TARIFA_DATALLE_1: '* Los precios indicados tienen el IVA incluído.',
        HOME_TARIFA_DATALLE_2: '* Los precios indicados incluyen el envío de detectores a la península y las islas baleares. Los envíos a las islas canarias tienen un coste adicional de 30 euros.',
        HOME_TARIFA_TABLA_TITULO: 'Ver tabla de precios [+]',
        INFO_USUARIO_TITULO: 'INFORMACIÓN PREVIA PARA LOS USUARIOS',
        INFO_USUARIO_SUBTITULO: 'Para iniciar la solicitud del Test Radón solo tiene que seguir estos pasos:',
        INFO_USUARIO_PASO1: 'Paso 1:',
        INFO_USUARIO_PASO1_TEXT: 'Antes de iniciar su solicitud, consulte toda la información relacionada con el servicio que desee contratar.',
        INFO_USUARIO_PASO1_DETALLE1: 'Los colegiados del Colegio de la Arquitectura Técnica de Barcelona pueden acceder con su usuario y contraseña personal.',
        INFO_USUARIO_PASO1_DETALLE2: 'Los colegiados que se integran dentro del Consejo General de la Arquitectura Técnica de España, pueden acceder con el usuario y contraseña de acceso a la ventanilla única del CGATE.',
        INFO_USUARIO_PASO1_DETALLE3: "El resto de usuarios deben registrarse en la plataforma.",
        INFO_USUARIO_PASO2: 'Paso 2:',
        INFO_USUARIO_PASO2_TEXT: 'Para poder gestionar su solicitud, es necesario formalizar un registro de usuario.',
        INFO_USUARIO_PASO3: 'Paso 3:',
        INFO_USUARIO_PASO3_TEXT: 'Inicie sesión con su cuenta de usuario y seleccione el Servicio de Test Radón que quiere contratar. Deberá rellenar todos los datos de la solicitud y realizar el pago.',
        INFO_USUARIO_PASO4: 'Paso 4:',
        INFO_USUARIO_PASO4_TEXT: 'Una vez realizado el pago, iniciará el procedimiento automático de envío de los detectores a la dirección indicada, a través de nuestro Servicio de mensajería: UPS.',
        INFO_USUARIO_PASO5: 'Paso 5:',
        INFO_USUARIO_PASO5_TEXT: 'Activar los detectores. Acceda con su perfil de usuario a su solicitud y confirme la recepción de los detectores para descargar las “instrucciones de colocación y puesta en marcha de los detectores”.',
        INFO_USUARIO_PASO6: 'Paso 6:',
        INFO_USUARIO_PASO6_TEXT: 'Desactivar los detectores. Acceda con su perfil de usuario a su solicitud y confirme la fecha de final de la medición para poder enviar los detectores al laboratorio.',
        INFO_USUARIO_PASO7: 'Paso 7:',
        INFO_USUARIO_PASO7_TEXT: 'Retorno de los detectores. Debe enviar todos los detectores siguiendo las instrucciones de devolución a nuestro centro de recepción del Cateb: C/Bon Pastor, núm. 5, planta 5ª. 08021 Barcelona indicando en el sobre el número de su solicitud.',
        INFO_USUARIO_PASO8: 'Paso 8:',
        INFO_USUARIO_PASO8_TEXT: 'Descargue el resultado del Test Radón que tendrá disponible al final del proceso de su solicitud.',
        HOME_BTN_CERRAR: 'Cerrar',
        HOME_LOGIN_TITLE: 'Ingreso al sistema',
        HOME_LOGIN_BTN: 'Iniciar Sesión',
        HOME_LOGIN_RECUPERAR_CONTRASENIA: 'Recuperar contraseña',
        HOME_REGISTER_TITLE: 'Crear una nueva cuenta',
        HOME_REGISTER_ES_COLEGIADO: '',
        HOME_BTN_CONTINUAR: 'Continuar',
        HOME_BTN_REGISTRAR: 'Registrar usuario',
        HOME_BTN_REGISTRAR_TAB1: 'Escriba su NIF / NIE',
        HOME_BTN_REGISTRAR_TAB2: 'Datos Generales',
        HOME_BTN_ATRAS: 'Regresar',
        HOME_REGISTER_DATOS_PERSONALES: 'Datos personales',
        HOME_REGISTER_DATOS_DIRECCION: 'Datos Dirección',
        HOME_REGISTER_DATOS_ACCESO: "Datos de acceso",
        HOME_REGISTER_INPUT_DOCUMENTO: 'Documento *',
        HOME_REGISTER_INPUT_NOMBRE: 'Nombre *',
        HOME_REGISTER_INPUT_APELLIDO: 'Apellidos *',
        HOME_REGISTER_INPUT_TIPO_VIA: 'Tipo vía *',
        HOME_REGISTER_INPUT_DIRECCION: 'Dirección *',
        HOME_REGISTER_INPUT_NUMERO: 'Número *',
        HOME_REGISTER_INPUT_PISO: 'Piso',
        HOME_REGISTER_INPUT_PUERTA: 'Puerta',
        HOME_REGISTER_INPUT_ESCALERA: 'Escalera',
        HOME_REGISTER_INPUT_COD_POSTAL: 'Código Postal *',
        HOME_REGISTER_INPUT_POBLACION: 'Población *',
        HOME_REGISTER_INPUT_TELEFONO: 'Teléfono de contacto *',
        HOME_REGISTER_INPUT_EMAIL: 'Email *',
        HOME_REGISTER_INPUT_PASSWORD: 'Contraseña *',
        HOME_REGISTER_INPUT_CCPASSWORD: 'Repetir contraseña *',
        HOME_REGISTER_NOTA: 'Los campos con un * son obligatorios.',
        HOME_SECTION1_TITLE: 'Nuestros servicios:',
        HOME_SECTION1_BOX1_TITLE: 'USUARIOS',
        HOME_SECTION1_BOX2_TITLE: 'PROFESIONALES',
        HOME_SECTION1_BOX1_SUBTITLE: 'Test Radón Básico',
        HOME_SECTION1_BOX2_SUBTITLE: 'Test Radón Profesional-justificación CTE',
        HOME_SECTION1_BOX1_CONTENIDO: 'Pensado para cualquier persona que quiera conocer los niveles de radón a los que está expuesto.',
        HOME_SECTION1_BOX2_CONTENIDO: 'Pensado para profesionales del sector, que como proyectista, dirección facultativa o entidad de control quiera medir la concentración de radón según las exigencias del CTE DB HS6.',
        HOME_CONTACTO_TITLE: 'Contáctanos',
        HOME_CONTACTO_INPUT_NAME: 'Nombre *',
        HOME_CONTACTO_INPUT_EMAIL: 'Email *',
        HOME_CONTACTO_INPUT_ASUNTO: 'Asunto *',
        HOME_CONTACTO_INPUT_MENSAJE: 'Mensaje *',
        HOME_CONTACTO_BTN: 'Enviar',

        HOME_PASSWORD_TITLE: 'Recuperar contraseña',
        HOME_PASSWORD_BTN: 'Recuperar',
        HOME_PASSWORD_INFO: '',
        HOME_PASSWORD_ENLACE: '',

        HOME_RADON_TITLE: 'Test Radón',
        HOME_RADON_INFO: 'Quizás no lo veas, pero puede estar',
        HOME_RADON_TEXT1: '¿Con qué aparatos haré la medición?',
        HOME_RADON_DESCRIPCION1: 'La medición se realiza mediante detectores pasivos de trazas alfa. Estos aparatos tienen dimensiones muy reducidas y autonomía durante todo el período de exposición, sin necesidad de fuentes de alimentación (enchufes).',
        HOME_RADON_TEXT2: '¿Qué resultado obtendré?',
        HOME_RADON_DESCRIPCION2: 'La concentración media de radón',
        HOME_RADON_DESCRIPCION3: 'durante el periodo de exposición de los detectores.',

        HOME_RADON_BASICO_TITLE: 'Test Radón Básico',
        HOME_RADON_BASICO_TEXT: 'Pensado para cualquier persona que quiera conocer los niveles de radón a los que está expuesto.',
        HOME_RADON_BASICO_TEXT_INFO1: 'El Test Radón Básico se ofrece en dos formatos:',
        HOME_RADON_BASICO_TEXT_SPAN1: 'Medición corta:',
        HOME_RADON_BASICO_TEXT_INFO2: 'podrás obtener una aproximación de los valores de radón obtenidos durante un periodo de entre 5-10 días.',
        HOME_RADON_BASICO_TEXT_SPAN2: 'Medición larga:',
        HOME_RADON_BASICO_TEXT_INFO22: 'podrás obtener una medida fiable de los niveles de radón en tu edificio durante un período de medición de 1 año (mínimo intervalos de 3 meses).',
        
        HOME_RADON_BASICO_TARIFA: 'Tarifas',
        HOME_RADON_BASICO_BOX1_TITLE: 'Medición corta',
        HOME_RADON_BASICO_BOX1_INFO: '<span>Tarifa general</span> <br/> <div>A partir de 125 €</div>',
        HOME_RADON_BASICO_BOX1_INFO2: '<span>Tarifa para Arquitectos Técnicos colegiados en España</span><br/> <div>A partir de 110 €</div>',

        HOME_RADON_BASICO_BOX2_TITLE: 'Medición larga', // (recomendada)
        HOME_RADON_BASICO_BOX2_INFO: '<span>Tarifa general</span> <br/> <div>A partir de 115 €</div>',
        HOME_RADON_BASICO_BOX2_INFO2: "<span>Tarifa para Arquitectos Técnicos colegiados en España</span> <br/> <div>A partir de 100 €</div>",
        HOME_RADON_BASICO_CONDICIONES: 'Condiciones:',
        HOME_RADON_BASICO_CONDICIONES_INFO1: 'Los usuarios con convenio se validan en el momento de la compra.',
        HOME_RADON_BASICO_CONDICIONES_INFO2: 'Los precios varían en función del número de detectores necesarios para asegurar la medición. Se aplicará un coste adicional para envíos a las Islas Canarias. (El precio indicado corresponde a la solicitud mínima de 2 detectores en península y baleares).', 
        HOME_RADON_BASICO_SERVICIO: 'El servicio Test Radón Básico incluye:',  
        HOME_RADON_BASICO_SERVICIO_INFO1: 'Acceso gratuito a la plataforma Test Radón donde tendrás disponible el seguimiento y resultado de tu prueba, así como información y asesoramiento personalizado en caso necesario.',
        HOME_RADON_BASICO_SERVICIO_INFO2: 'Envío por mensajería de los detectores (Compañía UPS). El número de detectores se determinará en función de los datos del edificio que especifique el solicitante. (NOTA: El precio indicado corresponde a la solicitud mínima de 2 detectores a la península y las islas baleares. Los envíos a las islas canarias tienen un coste adicional).',
        HOME_RADON_BASICO_SERVICIO_INFO3: 'Instrucciones de colocación y devolución de los detectores disponibles en todo momento en la plataforma.',
        HOME_RADON_BASICO_SERVICIO_INFO4: 'Resultado del Test en pdf con los valores medidos de la concentración media de radón durante el período de exposición.',
        HOME_RADON_BASICO_SERVICIO_INFO5: 'Contacto de soporte al usuario.',  

        HOME_RADON_BASICO_SERVICIO_NO_INCLUYE: 'El servicio Test Radón Básico NO incluye:',  
        HOME_RADON_BASICO_SERVICIO_NO_INCLUYE_INFO1: 'Retorno de los detectores al centro de recepción del Cateb: C/Bon Pastor, núm. 5, planta 5ª. 08021 Barcelona. ', 


        HOME_RADON_PROFESIONAL_TITLE: 'Test Radón Profesional-justificación CTE',
        HOME_RADON_PROFESIONAL_TEXT: 'Pensado para profesionales del sector, que como proyectista, dirección facultativa o entidad de control quiera medir la concentración de radón según las exigencias del CTE DB HS6.',
        HOME_RADON_PROFESIONAL_INFO: 'El Test Profesional solamente está disponible para mediciones de 3 meses, por lo tanto solo es posible realizar la solicitud de',
        HOME_RADON_PROFESIONAL_INFO_SPAN: 'Medición larga.',
        HOME_RADON_PROFESIONAL_TARIFA: 'Tarifas',
        HOME_RADON_PROFESIONAL_BOX_TITLE: 'Medición larga',
        HOME_RADON_PROFESIONAL_BOX_INFO1: '<span>Tarifa general</span> <br/> <div>A partir de 115 €</div>',
        HOME_RADON_PROFESIONAL_BOX_INFO2: '<span>Tarifa para Arquitectos Técnicos colegiados en España</span> <br/> <div>A partir de 100 €</div>',
        HOME_RADON_PROFESIONAL_CONDICIONES: 'Condiciones',
        HOME_RADON_PROFESIONAL_CONDICIONES_INFO1: 'Los usuarios con convenio se validan en el momento de la compra.',
        HOME_RADON_PROFESIONAL_CONDICIONES_INFO2: 'Los precios varían en función del número de detectores necesarios para asegurar la medición. Se aplicará un coste adicional para envíos a las Islas Canarias. (El precio indicado corresponde a la solicitud mínima de 2 detectores en península y baleares).',
        HOME_RADON_PROFESIONAL_SERVICIO: 'El servicio Test Radón Profesional incluye:',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO1: 'Acceso gratuito a la plataforma Test Radón donde tendrás disponible el seguimiento y resultado de tu prueba, así como información y asesoramiento personalizado en caso necesario.',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO2: 'Envío por mensajería de los detectores (Compañía UPS). El número de detectores es determinará en función de las zonas de muestreo bajo la justificación del proyectista, dirección facultativa o entidad de control. (NOTA: El precio indicado corresponde a la solicitud mínima de 2 detectores a la península y las islas baleares. Los envíos a las islas canarias tienen un coste adicional).',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO3: 'Instrucciones de colocación y devolución de los detectores disponibles en todo momento en la plataforma. ',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO4: 'Resultado del Test en pdf con los valores medidos de la concentración media de radón durante el período de exposición.',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO5: 'Contacto de soporte al usuario.',

        HOME_RADON_PROFESIONAL_SERVICIO_NO_INCLUYE: 'El servicio Test Radón Profesional NO incluye:',
        HOME_RADON_PROFESIONAL_SERVICIO_NO_INCLUYE_INFO1: 'Retorno de los detectores al centro de recepción del Cateb: C/Bon Pastor, núm. 5, planta 5ª. 08021 Barcelona.',

        RADON_MODAL_PAGADOR_TITLE: 'Quiero cambiar el pagador de la factura',
        RADON_MODAL_PAGADOR_OPTION1: 'Mis datos personales',
        RADON_MODAL_PAGADOR_OPTION2: 'A una empresa',
        RADON_MODAL_PAGADOR_OPTION3: 'A una persona',
        RADON_MODAL_PAGADOR_GUARDAR_PAGADOR: 'Guardar',
        RADON_MODAL_PAGADOR_INPUT_PAGADOR: 'Pagador *',

        RADON_MODAL_CAMBIAR_PAGADOR_TITLE: 'Datos del pagador',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PAGADOR: 'Documento *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_NOMBRE: 'Nombre  *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_RAZON_SOCIAL: 'Razón social *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_APELLIDO: 'Apellidos *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_TIPO_VIA: 'Tipo vía *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_DIRECCION: 'Dirección *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_NUMERO: 'Número *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PISO: 'Piso',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PUERTA: 'Puerta',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_ESCALERA: 'Escalera',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_CODIGO_POSTAL: 'Código Postal *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_POBLACION: 'Población *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_EMAIL: 'Email *',
        RADON_MODAL_CAMBIAR_PAGADOR_SALIR: 'Salir',
        RADON_MODAL_CAMBIAR_PAGADOR_ACEPTAR: 'Aceptar',

        DOCUMENTO_INVALIDO: 'Introduzca un documento válido.',
        DOCUMENTO_CORRECTO: 'Documento válido.',
        LOGIN_CORRECTO: 'Login iniciado correctamente',

        SIDEBAR_MENU1: 'Nuevo Test Radón',
        SIDEBAR_MENU2: 'Test Radón',
        SIDEBAR_MENU3: 'Mis datos',
        P_TEST_RADON_PROFESIONAL_JUSTIFICACION_CTE: `Test Radón Profesional-justificación CTE`,
        P_RESULTADO_TEST_1: `Resultado del Test en pdf con los valores medidos de la concentración media de radón durante el período de exposición.`,
        P_RESULTADO_TEST_2: `Resultado del Test en pdf con los valores medidos de la concentración media de radón durante el período de exposición. Con estos valores, el proyectista, dirección facultativa o entidad de control, podrá realizar la estimación del promedio anual de concentración de radón para la justificación de las exigencias previstas en el CTE DB HS6.`,
        OCULTAR: 'Ocultar',
        DATOS_PERSONALES: `Datos personales`,
        NOMBRE: `Nombre`,
        APELLIDOS: `Apellidos`,
        MOVIL: 'Móvil',
        DATOS_DIRECCION: `Datos Dirección`,
        TIPO_VIA: `Tipo de vía`,
        DIRECCION: `Dirección`,
        NUMERO: `Número`,
        PISO: `Piso`,
        PUERTA: `Puerta`,
        ESCALERA: `Escalera`,
        CODIGO_POSTAL: `Código postal`,
        POBLACION: `Población`,
        P_MODIFICAR_DATOS_CONTACTO_CATEB: `Para modificar vuestros datos, póngase en contacto con el Administrador de Test Radón`,
        PRIVACIDAD_COOKIES: `Privacidad y Cookies`,
        AREA_ADMINISTRACION: `Área de Administración`,
        ACCIONES: `Acciones`,
        VER_SOLICITUD: `Ver solicitud`,
        SOLICITUDES: `Solicitudes`,
        BORRADOR: `Borrador`,
        PAGADO: `Pagado`,
        SOLICITADO: `Solicitado`,
        MEDICION: `Medición`,
        PENDIENTE_ANALISIS: `Pendiente de análisis`,
        FILTROS: `Filtros`,
        P_SEGURO_ELIMINAR_PLANTA: `¿Seguro que desea eliminar la planta?`,
        SI_ELIMINAR: `Si, eliminar`,
        P_PLANTA_PLANTA_BAJA_PLANTA_1: `Planta: (Planta Baja, Planta -1, Planta 3)`,
        P_DISPONE_LOCALES_HABITABLES: `¿Dispone de locales habitables?`,
        IDENTIFIQUE_USO: `Identifique el uso`,
        LOCALES_HABITABLES: `Locales Habitables`,
        P_SALA_DORMITORIO_COCINA: `Sala de estar, Dormitorio, Baño, Despacho, Cocina`,
        PLANTA_HABITABLES: `planta habitables`,
        ALTURA_PLANTA: `Altura planta`,
        COMPARTIMENTADO: `Compartimentado`,
        SIN_COMPARTIMENTAR: `Sin compartimentar`,
        EDITAR: `Editar`,
        ACEPTAR: `Aceptar`,
        CONTINUAR: `Continuar`,
        DETERMINACION_NUMERO_DETECTORES: `Determinación del número de detectores`,
        P_IDENTIFICA_PLANTA: `Identifica la planta. Ejemplo: Planta sótano -1, planta baja, planta 1a`,
        P_EJEMPLO_RECINTOS_TRABAJO_AULAS: `p. ejemplo: recintos de trabajo o abiertos al público como aulas, bibliotecas, habitaciones hospitalarias, despachos, salas de espera o de reuniones, etc`,
        SELECCIONE: `Seleccione`,
        DATOS_FACTURACION: `Datos de facturación`,
        TOTAL_SOLICITUD_DETECTORES: `Total de la solicitud de detectores`,
        RAZON_SOCIAL: `Razón social`,
        N_DETECTOR: `N° Detector`,
        UBICACION_DETECTOR: `Ubicación Detector`,
        REGISTRAR_INFORMACION: `Registrar información`,
        P_DETECTORES_PROCESO_ANALISIS: `Los detectores están en procesos de análisis`,
        ENVIAR_A_LABORATORIO: `Enviar a laboratorio`,
        P_RECIBIDO_DETECTORES: `He recibido los detectores`,
        P_DESCARGAR_INFORME_RESULTADOS: `Descargar Informe de resultados`,
        DEVOLUCION_DETECTORES: `Devolución de detectores`,
        PLANOS: `Planos`,
        AGREGAR_PLANO: `Agregar plano`,
        ELIMINAR_PLANO: `Eliminar plano`,
        FICHERO_ADJUNTO: `Fichero adjunto`,
        PLANO: `Plano`,
        ELIMINAR: `Eliminar`,
        EL_CAMPO_ES_OBLIGATORIO: `El campo es obligatorio`,
        TRANSPORTE: `Transporte`,
        SENSORES_ADICIONALES: `Sensores adicionales`,
        PRECIO_SENSORES_ADICIONALES: `Precio total sensores adicionales`,
        PAGAR: `Pagar`,
        PROVINCIA: `Provincia`,
        MUNICIPIO: `Municipio`,
        SOLICITUD_TEST_RADON: `Solicitud Test Radón`,
        SUPERFICIE_UTIL: `Superficie Útil`,
        P_QUIERES_ANADIR_DETECTOR: `¿Quieres añadir algún detector más?`,
        GUARDAR_COMO_BORRADOR: `Guardar como borrador`,
        ENVIO_A_CANARIAS: `Envío a Canarias`,
        DESCARGAR_INSTRUCCIONES: `Descargar instrucciones`,
        DESCARGAR_ANEXO: `Descargar anexo`,
        RESULTADO: `Resultado`,
        P_CONTINUAR_NECESARIO_ALMENOS_1_SENSOR: `Para continuar, debe existir al menos 1 detector`,
        P_GESTIONAR_PLANOS_CLICK_AQUI: `Para gestionar sus planos, haga clic aquí`,
        CONDICIONES_CONTRATACION: `Condiciones de contratación`,
        P_MANIFIESTO_LEIDO_ACEPTO: `Manifiesto que he leído y acepto las`,
        RECHAZAR: `Rechazar`,
        P_CONTACTAR_CON_NOSOTROS: `Si quieres contactar con nosotros puedes hacerlo mediante el teléfono o escribiendo al correo electrónico`,
        ACCESO: `Acceso`,
        APARTAMENTOS: `Apartamentos`,
        AUTOPISTA: `Autopista`,
        AUTOVIA: `Autovía`,
        AVENIDA: `Avenida`,
        BAJADA: `Bajada`,
        BARRANCO: `Barranco`,
        BARRIO: `Barrio`,
        BLOQUE: `Bloque`,
        CALZADA: `Calzada`,
        CAMINO: `Camino`,
        CALLE: `Calle`,
        CALLEJON: `Callejón`,
        CARRETERA: `Carretera`,
        CASAS: `Casas`,
        CIRCUNVALACION: `Circunvalación`,
        COLONIA: `Colonia`,
        COMPLEJO: `Complejo`,
        CORREDOR: `Corredor`,
        CORRIOL: `Corriol`,
        COSTA: `Costa`,
        ATAJO: `Atajo`,
        EJE: `Eje`,
        GLORIETA: `Glorieta`,
        GRAN_VIA: `Gran vía`,
        GRUPO: `Grupo`,
        ISLA: `Isla`,
        JARDINES: `Jardines`,
        LUGAR: `Lugar`,
        MIRADOR: `Mirador`,
        MOLL: `Moll`,
        MURALLA: `Muralla`,
        PARAJE: `Paraje`,
        PARQUE: `Parque`,
        PARTIDA: `Partida`,
        PASADILLO: `Pasadillo`,
        PASAJE: `Pasaje`,
        PISTA: `Pista`,
        PLAZA: `Plaza`,
        PLACETA: `Placeta`,
        PLAYA: `Playa`,
        POLIGONO: `Polígono`,
        POLIGONO_INDUSTRIAL: `Polígono industrial`,
        PUENTE: `Puente`,
        PUERTO: `Puerto`,
        PORTAL: `Portal`,
        PROLONGACION: `Prolongación`,
        SUBIDA: `Subida`,
        RINCON: `Rincón`,
        RAMBLA: `Rambla`,
        RAVAL: `Raval`,
        RIAL: `Rial`,
        RIERA: `Riera`,
        RONDA: `Ronda`,
        ROTONDA: `Rotonda`,
        SECTOR: `Sector`,
        TRAVESIA: `Travesía`,
        TRAVESERA: `Travessera`,
        URBANIZACION: `Urbanización`,
        VECINDARIO: `Vecindario`,
        VIA: `Vía`,
        VIADUCTO: `Viaducto`,
        VIAL: `Vial`,
        ZONA: `Zona`,
        DATOS_VIVIENDA_EDIFICIO: `Datos de la vivienda/edificio`,
        V_OBLIGATORIO_NUMERO_COLEGIADO: `El número de colegiado es obligatorio.`,
        V_OBLIGATORIO_NOMBRE: `El nombre es obligatorio.`,
        V_OBLIGATORIO_TELEFONO: `El teléfono es obligatorio.`,
        V_OBLIGATORIO_CONTRASENIA: `La contraseña es obligatoria.`,
        V_OBLIGATORIO_DOCUMENTO: `El documento es obligatorio.`,
        V_OBLIGATORIO_CAMPO: `El campo es obligatorio.`,
        V_OBLIGATORIO_CAMPO_NA: `El campo es obligatorio. Indicar “N/A” si no aplica.`,
        V_OBLIGATORIO_CORREO: `El correo es obligatorio.`,
        V_OBLIGATORIO_DIRECCION: `La dirección es obligatoria.`,
        V_OBLIGATORIO_PROVINCIA: `La provincia es obligatoria.`,
        V_CONFIRMACION_OBLIGATORIO: `La confirmación de contraseña es obligatoria.`,
        V_OBLIGATORIO_POBLACION: `La población es obligatoria.`,
        V_OBLIGATORIO_CODIGO_POSTAL: `El código postal es obligatorio.`,
        V_OBLIGATORIO_EMAIL: `El email es obligatorio.`,
        V_OBLIGATORIO_PAIS: `El país es obligatorio.`,
        V_OBLIGATORIO_TIPO_DOCUMENTO: `El tipo de documento es obligatorio.`,
        V_OBLIGATORIO_MANIFIESTO: `El manifiesto es obligatorio.`,
        V_OBLIGATORIO_APELLIDOS: `Los apellidos son obligatorio.`,
        V_OBLIGATORIO_NUMERO_DETECTOR: `El número de detector es obligatorio.`,
        V_OBLIGATORIO_VIA_PAGO: `La vía de pago es obligatoria.`,
        CONTRASENIA_ANTIGUA: `Contraseña antigua`,
        CONTRASENIA_NUEVA: `Contraseña nueva`,
        CONFIRMACION_CONTRASENIA_NUEVA: `Confirmación Contraseña nueva`,
        AGREGAR_SENSORES: `Agregar sensores`,
        CANT_SENSORES: `Cant. sensores`,
        P_USUARIO_CONTRASENIA_INVALIDOS: `Usuario o contraseña incorrectas.`,
        P_DESCARGANDO_DOCUMENTO: `Se está descargando el documento.`,
        EDIFICIO: `Edificio`,
        COPIAR_DATOS_PETICIONARIO: `Copiar datos del peticionario`,
        TIPO_DE_PROPIETARIO: `Tipo de propietario`,
        NUEVA_DIRECCION_ENVIO: `Nueva dirección de envío`,
        P_MANIFIESTO_INFORMACION_PROTECCION_DATOS: `MANIFIESTO que he leído y ACEPTO el contenido de la información precedente relativa a la`,
        P_MANIFIESTO_INFORMACION_PROTECCION_DATOS_2: `protección de datos de carácter personal.`,
        p_MANIFIESTO_DATOS_TERCERAS_PERSONAS: `MANIFIESTO que he leído y ACEPTO que, si facilito los`,
        p_MANIFIESTO_DATOS_TERCERAS_PERSONAS_2: `datos a terceras personas, tendré que informar y obtener su consentimiento previo en relación con los aspectos relacionados en el presente documento.`,
        P_MANIFIESTO_DATOS_PERSONALES_FACILITADOS: `MANIFIESTO que autorizo a que mis datos personales sean facilitados al Consejo General de la Arquitectura Técnica de España, a la Administración, etc. para finalidades estadísticas y de estudio sobre la situación y afectación del radón en nuestro territorio.`,
        P_CAMBIADO_CONTRASENIA_CORRECTAMENTE: `Se ha cambiado la contraseña correctamente`,
        CAMBIAR: `Cambiar`,
        CANCELAR: `Cancelar`,
        TEST_RADON_PROFESIONAL: `Test Radón Profesional`,
        P_MEDICION_DISPONIBLE_MESES: `Disponible para mediciones de mínimo 3 meses`,
        RECUPERAR_DATOS_PETICIONARIO: `Recuperar datos del peticionario`,
        PASEO: `Paseo`,
        NOMBRE_APELLIDOS: `Nombre y apellidos`,
        EMAIL: `Email`,
        TELEFONO: `Teléfono`,
        PREGUNTAS_FRECUENTES: `Preguntas frecuentes`,
        CONSULTA_COLEGIOS_CONVENIO: `Consulta los colegios con convenio`,
        EN_ESTE_ENLACE: `En este enlace`,
        ATENCION_DE: 'A la atención de *',
        CONTACTAR: 'Contactar',
        P_ALERTA_HOME: `Os informamos que del 5 al 23 de agosto de 2024 el servicio de contacto permanecerá cerrado. Durante este periodo podéis utilizar la plataforma con normalidad y las consultas serán resueltas a partir del 23 de agosto.`,
        TARIFA_MODAL_TABLE_TITULO1: 'Num. total detectores por solicitud',
        TARIFA_MODAL_TABLE_TITULO2: 'Tarifa General (en euros/IVA incl.)',
        TARIFA_MODAL_TABLE_TITULO3: 'Tarifa colegiado (en euros/IVA incl.)',
        TARIFA_MODAL_TABLE_DETECTOR_ADICIONAL: 'Detector adicional',
        ZONA_NO_CALISIFICADA: 'No Clasificado'

    }
}
export default lang;