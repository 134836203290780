import React, { useEffect, useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useFetch } from 'hooks/useFetch';
import { useForm } from 'hooks/useForm';
import { useValidator } from 'hooks/useValidator';
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { MsgInputServer } from 'components/MsgInput/MsgInputServer';
import { InputText } from 'components/Inputs/InputText';
import { useLang } from 'language';
import jwt from 'jwt-decode' // import dependency
import { AuthContext } from 'auth/AuthContext';
import { types } from 'types/types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ValidateSpanishID from 'helpers/validateSpanishID';
import { inIframe } from 'helpers/helpers';

export const ModalLogin = (props) => {
    const history = useHistory();
    const { dispatch } = useContext(AuthContext);
    const locale = useLang();
    const { data, handleClose, handleOpenModal_Password } = props;
    const [fetchLoginExterno, fetchLoginExternoData] = useFetch('POST', `/auth/login`)

    const [formLoginExterno, handleInputChangeLoginExterno] = useForm({
        nif: '',
        password: ''
    })

    const [valuesValid, handleVerifiedValues] = useValidator({
        nif: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_DOCUMENTO')]
            }
        },
        password: {
            required: {
                isOk: true,
                msgError: [locale('V_OBLIGATORIO_CONTRASENIA')]
            }
        }
    })

    const handleSubmitLogin = (e) => {
        e.preventDefault()
        if (handleVerifiedValues(formLoginExterno)) {
            const documento = formLoginExterno.nif;
            const validationResult = ValidateSpanishID(documento);
            if (!validationResult.valid) {
                toast.error(locale('DOCUMENTO_INVALIDO'));
                return false;
            }

            fetchLoginExterno({
                body: formLoginExterno
            })
        }
    }

    const verificarDocumento = (event) => {
        const inputValue = event.target.value.toUpperCase().replace(/\s/, '');
        handleInputChangeLoginExterno({
            target: {
                name: 'nif',
                value: inputValue
            }
        })
    }

    const analizarDocumento = (e) => {
        const documento = e.target.value;
        const validationResult = ValidateSpanishID(documento);

        if (!validationResult.valid) {
            handleInputChangeLoginExterno({
                target: {
                    name: 'nif',
                    value: ''
                }
            })
            toast.error(locale('DOCUMENTO_INVALIDO'));
        } else {
            toast.success(locale('DOCUMENTO_CORRECTO'));
        }
    }

    useEffect(() => {

        if (fetchLoginExternoData.data) {

            const token = fetchLoginExternoData?.data?.data?.token

            if (token) {

                // toast.success(locale('LOGIN_CORRECTO'));

                // Esta en iframe de .cat
                // if (inIframe()) {

                //     // window.top.location.href = `${process.env.PUBLIC_URL}?token=${token}`

                //     window.top.location.href = process.env.PUBLIC_URL

                //     return
                // }

                // const lastPath = sessionStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_lastPath') || '/';
                const lang = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;

                const user = jwt(token).user

                dispatch({
                    type: types.login,
                    payload: {
                        ...user,
                        token: token,
                    },
                    lang
                });

                //handleChangeIdioma(token, lang)

                // history.replace(lastPath);

            } else {
                toast.error(locale('P_USUARIO_CONTRASENIA_INVALIDOS'));
            }
        }

    }, [fetchLoginExternoData])


    return (
        <Modal show={data.value} onHide={handleClose} bsPrefix="modal" size="md" className="animate__animated animate__fadeIn">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{locale('HOME_LOGIN_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center my-3">
                    <div className="col-lg-8">

                        <Form>

                            <Form.Group className="mb-3">
                                <InputText
                                    label='NIF / NIE'
                                    className=''
                                    name='nif'
                                    value={formLoginExterno.nif}
                                    onChange={(e) => {
                                        //handleInputFormTestRadon(e)
                                        verificarDocumento(e)
                                    }}
                                    //onBlur={analizarDocumento}
                                    placeholder="nif"
                                    validation={valuesValid}
                                    fetchData={fetchLoginExternoData}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <InputText
                                    label={locale('CONTRASENIA')}
                                    className=''
                                    name='password'
                                    type="password"
                                    value={formLoginExterno.password}
                                    onChange={handleInputChangeLoginExterno}
                                    placeholder="password"
                                    validation={valuesValid}
                                    fetchData={fetchLoginExternoData}
                                />
                            </Form.Group>

                            <div className="text-center">
                                <MsgInputServer obj={fetchLoginExternoData.data} />
                            </div>

                            <div className="d-grid gap-2 mt-3 p-2">
                                <ButtonSpinner
                                    variant="primary" type="submit" className="ms-2"
                                    handleSubmit={handleSubmitLogin} fetchDataLoading={[fetchLoginExternoData.loading]}
                                    value={locale('HOME_LOGIN_BTN')}
                                />
                            </div>

                            <div className="text-center mt-2">
                                <p type="button" variant="color-primary text-decoration-underline" id="passwordHelp" className="form-text my-0" onClick={() => {
                                    handleOpenModal_Password({
                                        action: 'EXTRA',
                                    })
                                }}>
                                    {locale('HOME_LOGIN_RECUPERAR_CONTRASENIA')}
                                </p>
                            </div>

                        </Form>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer className='justify-content-start'>
                <Button variant="default" onClick={handleClose}>
                    {locale('HOME_BTN_CERRAR')}
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
}